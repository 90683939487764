<template>
  <div class="container">
    <div class="main">
      <div class="main-tab">
        <el-radio-group v-model="activeMainTab" @change="handleChange">
          <el-radio-button
            v-for="(item, index) in mainTab"
            :label="item.value"
            :key="index"
            >{{ item.label }}</el-radio-button
          >
        </el-radio-group>
      </div>
      <div class="main-list">
        <div class="list-header item">
          <div>번호</div>
          <div>제목</div>
          <div>날짜</div>
        </div>
        <div class="list-content">
          <div
            class="item"
            v-for="(item, index) in listData"
            :key="index"
            @click="goToDetail(item)"
          >
            <div>{{ item.entity.codeIndex }}</div>
            <div>
              <span>{{ item.entity.sonTypes }}</span>
              {{ item.entity.title }}
              <span>{{ item.entity.language }}</span>
              <img
                src="@/assets/icon_new.gif"
                v-if="item.entity.isTodayUpdate"
              />
            </div>
            <div class="item-time">{{ item.entity.date }}</div>
          </div>
        </div>
      </div>
      <div class="pagination-box">
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page="pageNum"
          :page-size="pageSize"
          :total="total"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { getList } from "@/api/api.js";
import dayJs from "dayjs";
export default {
  name: "List",
  components: {},
  data() {
    return {
      mainTab: [
        { label: "영화", value: "1", name: "movie" },
        { label: "드라마", value: "2", name: "drama" },
        { label: "예능", value: "3", name: "ent" },
        { label: "tv방송", value: "4", name: "tv" },
      ],
      activeMainTab: "1",
      activeMainTabSelecct: {},
      listData: [],
      total: 0,
      pageNum: 1,
      pageIndex: 1,
      pageSize: 20,
      tab: "movie",
      id: -1,
    };
  },
  mounted() {
    let data = {
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      tab: this.tab,
      id: this.id,
    };
    this.getListData(data);
  },
  methods: {
    getListData(data) {
      getList(data).then((res) => {
        this.listData = res.fileList.map((item) => {
          const now = dayJs(new Date().getTime()).format("YYYY-MM-DD");
          item.entity.isTodayUpdate = false;
          if (item?.entity?.date) {
            if (item.entity.date.includes(now)) {
              item.entity.date = item.entity.date.split(" ")[1];
              item.entity.isTodayUpdate = true;
            } else item.entity.date = item.entity.date.split(" ")[0];
          }
          return item;
        });
        this.total = res.totalCount;
      });
    },
    handleCurrentChange(val) {
      let data = {
        pageIndex: val === null ? 1 : val,
        pageSize: this.pageSize,
        tab: this.activeMainTabSelecct.name,
        id: this.id,
      };
      this.getListData(data);
    },
    handleChange(e) {
      this.activeMainTabSelecct = this.selectmainTab(e);
      let data = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        tab: this.activeMainTabSelecct.name,
        id: this.id,
      };
      this.getListData(data);
    },
    selectmainTab(val) {
      let list = this.mainTab;
      let data = {};
      list.forEach((el) => {
        if (el.value === val) {
          data = el;
        }
      });
      return data;
    },
    changeMainTab(item) {
      this.activeMainTab = item.value;
    },
    goToDetail(item) {
      router.push({
        path: "/detail",
        query: {
          id: item.entity.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scope>
.container {
  background: url(@/assets/main_bg.gif) repeat;
  padding: 8px 0;
  min-width: 1160px;
  height: calc(100% - 16px);
}
.main {
  width: 1160px;
  margin: 0 auto;
  overflow: hidden;
  background: #fff;
  padding: 0 15px;
  min-height: 100%;
}
.main-tab {
  margin-top: 25px;
  margin-bottom: 6px;
}
.main-tab .el-radio-group .el-radio-button__inner {
  border-radius: 0 !important;
  font-size: 12px !important;
  padding: 0 10px !important;
  height: 25px !important;
  line-height: 25px !important;
  box-shadow: none !important;
}
.main-tab .el-radio-group .is-active .el-radio-button__inner {
  background: #3865cd !important;
  border: 1px solid #3865cd !important;
}
.main-tab .el-radio-group .is-active {
  background: #3865cd;
  border: 1px solid #3865cd;
}
.main-tab .el-radio-group label:not(.is-active).el-radio-button {
  &.el-radio-button__inner:hover {
    color: #3865cd;
  }
}
.main-list {
  min-height: calc(100% - 100px);
}
.main-list .item {
  display: flex;
  color: #5d5d5d;
  height: 30px;
  line-height: 30px;
  border-bottom: 1px dashed #dedede;
}

.main-list .item div:nth-child(1) {
  width: 40px;
  text-align: center;
}
.main-list .item div:nth-child(2) {
  width: calc(100% - 200px);
  color: #333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.main-list span {
  width: 60px;
  padding-left: 5px;
  color: #3865cd;
  margin-right: 5px;
  vertical-align: middle;
  text-decoration: none;
}
.main-list .item div:nth-child(3) {
  width: 100px;
  text-align: center;
}
.main-list .list-header {
  text-align: center;
  border-top: 2px solid #3865cd;
  border-bottom: 1px solid #aaaaaa;
  color: #444444 !important;
  height: 35px;
  line-height: 35px;
  background: #fafafa;
  font-weight: bold;
  font-style: normal !important;
}
.main-list .list-content .item div:nth-child(3) {
  font-style: oblique;
  color: #999;
}
.main-list .list-content .item:hover {
  text-decoration: underline;
  cursor: pointer;
}
.pagination-box {
  display: flex;
  justify-content: center;
  padding: 30px 0;
  .el-pagination.is-background .el-pager li {
    font-size: 12px;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #3865cd;
  }
}
</style>
