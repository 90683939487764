<template>
  <div class="container">
    <div class="main">
      <div class="detail-title">
        <span>[{{ listData.sonTypes }}]</span>
        {{ listData.title }}
        <div class="goto-list" @click="goToList"><strong>목록</strong></div>
      </div>
      <div class="detail-date">등록일: {{ listData.date }}</div>
      <div class="main-list main-list2">
        <div class="list-content">
          <div class="item" v-for="(item, index) in listfilesData" :key="index">
            <a
              :href="getdownloadurl(item)"
              class="link"
              target="_blank"
            >
              <img
                border="0"
                alt=""
                src="@/assets/dw.png"
                width="22"
                align="top"
              />{{ item.fileName }}</a
            >
          </div>
        </div>
      </div>
      <div
        class="detail-image"
        v-for="(item, index) in listimagesData"
        :key="index"
      >
        <img
          border="0"
          alt=""
          :src="getimagesurl(item)"
          width="754"
          align="top"
          height=""
        />
      </div>
      <!-- <div class="title-text">프리한19.E422.240701.1080p.H264-F1RST</div>
      <div class="main-list">
        <div class="list-header">
          <div>관련자료 목록</div>
        </div>
        <div class="list-content">
          <div
            class="item"
            v-for="(item, index) in listData"
            :key="index"
            @click="goToDetail(item)"
          >
            <div>{{ item.index }}</div>
            <div>{{ item.title }}</div>
            <div>{{ item.date }}</div>
          </div>
        </div>
        <div class="more" @click="goToList('프리한19')">
          <span>프리한19</span> 더검색하기 >>
        </div>
        <div class="footer-btn">
          <div class="goto-list" @click="goToList"><strong>목록</strong></div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { getList } from "@/api/api.js";
import { geturl } from "@/utils/url.js";
export default {
  data() {
    return {
      url: "",
      id: -1,
      listData: {},
      listfilesData: [],
      listimagesData: [],
    };
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.url = geturl();
    this.getListData();
  },
  methods: {
    getimagesurl(item) {
      const imageUrl = this.url + "/" + item.imgPath; //.replace("wwwroot/", "")
      return imageUrl;
    },
    getdownloadurl(item) {
      const fileUrl = this.url + "/" + item.filePath; //.replace("wwwroot/", "")
      return fileUrl; 
    },
    getListData() {
      let data = {
        pageIndex: 1,
        pageSize: 2,
        tab: "",
        id: Number(this.id),
      };
      getList(data).then((res) => {
        if (res.fileList.length > 0) {
          this.listData = res.fileList[0].entity;
          this.listfilesData = res.fileList[0].files;
          this.listimagesData = res.fileList[0].images;
        }
      });
    },
    goToDetail(item) {
      router.push({
        path: "/detail",
        query: {
          id: item.entity.id,
        },
      });
    },
    goToList() {
      router.push({ path: "/", query: {} });
    },
  },
};
</script>

<style lang="scss" scope>
.container {
  background: url(@/assets/main_bg.gif) repeat;
  padding: 8px 0;
  min-width: 1160px;
  height: calc(100% - 16px);
}
.main {
  width: 1160px;
  margin: 0 auto;
  overflow: hidden;
  background: #fff;
  padding: 0 15px;
  min-height: 100%;
  padding-top: 15px;
}
.main-tab {
  margin-top: 25px;
  margin-bottom: 6px;
}
.main-tab .el-radio-group .el-radio-button__inner {
  border-radius: 0 !important;
  font-size: 12px !important;
  padding: 0 10px !important;
  height: 25px !important;
  line-height: 25px !important;
  box-shadow: none !important;
}
.main-tab .el-radio-group .el-radio-button__inner:hover {
  color: #3865cd;
}
.main-tab .el-radio-group .is-active .el-radio-button__inner {
  background: #3865cd !important;
  border: 1px solid #3865cd !important;
}
.main-tab .active {
  background: #3865cd;
  border: 1px solid #3865cd;
}
.main-list .item {
  display: flex;
  color: #5d5d5d;
  height: 30px;
  line-height: 30px;
  border-bottom: 1px dashed #dedede;
}
.main-list .item div:nth-child(1) {
  width: 40px;
  text-align: center;
}
.main-list .item div:nth-child(2) {
  width: calc(100% - 140px);
  color: #333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}
.main-list span {
  width: 60px;
  padding-left: 5px;
  color: #3865cd;
  margin-right: 5px;
  vertical-align: middle;
  text-decoration: none;
}
.main-list .item div:nth-child(3) {
  width: 100px;
  text-align: center;
}
.main-list .list-header {
  text-align: center;
  border-top: 2px solid #3865cd;
  border-bottom: 1px solid #aaaaaa;
  color: #444444 !important;
  height: 35px;
  line-height: 35px;
  background: #fafafa;
  font-weight: bold;
  font-style: normal !important;
}
.main-list .list-content .item div:nth-child(3) {
  font-style: oblique;
  color: #999;
}
.main-list .list-content .item .link {
  display: flex;
  align-items: center;
}
.main-list2 {
  padding-bottom: 95px;
}
.detail-title {
  display: flex;
  align-items: center;
  height: 54px;
  line-height: 54px;
  padding: 0 0px;
  border-top: 2px solid #2d3d4e;
  border-bottom: 1px solid #aaaaaa;
  font-size: 16px;
  font-family: "NanumSquareRound";
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
  span {
    color: #3865cd;
    margin-right: 5px;
  }
}
.title-text {
  color: #333;
  font-size: 13px;
  padding: 30px 0;
}
.detail-date {
  height: 35px;
  line-height: 35px;
  color: #999;
  text-align: left;
  border-bottom: 1px solid #dcdcdc;
}
.detail-image {
  text-align: center;
  .download {
    display: block;
    content: "\e7a3";
  }
}
.goto-list {
  width: 54px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: #2d3d4e;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
}
.more {
  text-align: center;
  padding: 15px 0;
  border-bottom: 1px solid #dedede;
  font-weight: bold;
  color: #3865cd;
  cursor: pointer;
  span {
    color: #000;
  }
}
.footer-btn {
  display: flex;
  justify-content: flex-end;
  padding: 30px 0;
}
</style>
