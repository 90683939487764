import Vue from 'vue';
import Router from 'vue-router';
import List from '../components/list.vue';
import Detail from '../components/detail.vue';

Vue.use(Router);

export default new Router({
    routes: [
      {
        path: '/',
        name: 'List',
        component: List
      },
      {
        path: '/detail',
        name: 'Detail',
        component: Detail
      }
      // ...其他路由
    ]
  });