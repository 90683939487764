<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: "App",
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style>
html, body {
  height: 100%;
  padding: 0;
  margin: 0;
}
#app {
  font-family: "돋움", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  font-size: 12px;
  height: 100%;
}
a {
  text-decoration: none;
}
</style>
